import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  padding: 1rem;

  img {
    width: 10rem;
    height: 10rem;
    margin: 0 2rem;
    border-radius: 50%;
    border: 5px solid #fe8418;
  }

  p {
    font-size: 0.85rem;
    overflow-x: hidden;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 10px;
    }

    strong {
      padding: 1rem 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin: 2rem 0;
    }

    p {
      padding: 0 1.5rem;
    }
    strong {
      margin-left: 1.4rem;
    }
  }

  @media screen and (max-width: 468px) {
    p {
      font-size: 0.8rem;
    }
    strong {
      font-size: 0.8rem;
    }
    img {
      width: 8rem;
      height: 8rem;
    }
  }

  @media screen and (max-width: 414px) {
    justify-content: flex-start;

    img {
      margin: 0.5rem 0;
    }
    p {
      padding: 0;
      position: relative;
      overflow: visible;
      font-size: 0.7rem;
    }
    strong {
      font-size: 0.7rem;
      margin-left: 0;
    }
  }
`
