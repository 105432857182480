import React from "react";
import Slider, { CustomArrowProps } from "react-slick";
import CardCarousel from "~/shared/CardDepoCarrusel";
import { Container } from "react-bootstrap";
import { Content, Border } from "./styles";

import { depoimentos } from "~/utils/depoimentos";

const NextArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                background: "orange",
                zIndex: 10,
                borderRadius: "50%",
            }}
            onClick={onClick}
        >
            <img src="/images/arrow-right.png" />
        </div>
    );
};

const PrevArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                background: "orange",
                zIndex: 10,
                borderRadius: "50%",
            }}
            onClick={onClick}
        >
            <img src="/images/arrow-left.png" />
        </div>
    );
};

const DepoCarousel = () => {
    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <NextArrowCarusel />,
        prevArrow: <PrevArrowCarusel />,
        responsive: [
            {
                breakpoint: 1026,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Border>
            <Container>
                <Content>
                    <Slider {...setting}>
                        {depoimentos.map((depo) => (
                            <CardCarousel
                                key={depo.id}
                                name={depo.name}
                                depoimento={depo.depoimento}
                                image={depo.image}
                            />
                        ))}
                    </Slider>
                </Content>
            </Container>
        </Border>
    );
};

export default DepoCarousel;
