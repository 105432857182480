import { CustomArrowProps } from 'react-slick'

export const NextArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-right.png" />
      </div>
    )
  }

  export const PrevArrowCarusel = (props: CustomArrowProps) => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          background: 'orange',
          zIndex: 10,
          borderRadius: '50%'
        }}
        onClick={onClick}
      >
        <img src="/images/arrow-left.png" />
      </div>
    )
  }
