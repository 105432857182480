import styled from "styled-components";

export const Border = styled.div`
    /* border-top: 2px solid #000; */
`;

export const Content = styled.div`
    padding: 1rem 2rem;

    /* *classes do carousel */

    .slick-arrow {
        width: 2rem;
        height: 2rem;

        :before {
            content: none;
        }
    }

    .slick-next {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-prev {
        padding-left: 12px;
        padding-top: 8px;
    }

    .slick-slider {
        margin-top: 1rem;

        div {
            margin: 0 auto;
        }
    }

    @media (max-width: 414px) {
        padding: 1rem 1rem;

        .slick-next {
            top: 25%;
            right: 0%;
        }

        .slick-prev {
            top: 25%;
            left: 0%;
        }
    }
    /**-------------------- */
`;
