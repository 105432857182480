type DepoimentoProps = {
    id: string;
    name: string;
    depoimento: string;
    image: string;
};

export const depoimentos: DepoimentoProps[] = [
    {
        id: "01",
        name: "Rodrigo Fábio",
        depoimento:
            "Eu vejo o Fotop como uma ótima oportunidade de aprendizado e de trabalho, como de entrar e se manter no mercado  da fotografia, uma empresa de boa relação interpessoal",
        image: "/images/depo-01.jpg",
    },
    {
        id: "02",
        name: "Bruno António Centeio",
        depoimento:
            "O Fotop sem dúvida alguma foi a empresa que me proporcionou a oportunidade de me inserir no mercado da fotografia transformando uma paixão na minha atividade profissional! Através da empresa tive a chance de me estruturar e adquirir meus equipamentos e tantas outras coisas que hoje fazem da fotografia minha atividade principal! Além de todas as pessoas que conheci e me apoiaram durante todo esse período e hoje fazem parte da minha caminhada profissional!",
        image: "/images/depo-02.jpg",
    },
    {
        id: "03",
        name: "Geovani da Silva Araújo",
        depoimento:
            'Olá sou Geovani, fotógrafo parceiro do quinto andar". Essa é a frase que eu começo o vídeo tour para o quinto andar, e já devo ter repetido mais de 300 vezes, graças ao pessoal do Fotop eu que só fotografava pessoas, hoje tenho a oportunidade de me deram a oportunidade de fotografar imóveis com uma alta demanda.',
        image: "/images/depo-03.jpg",
    },
    {
        id: "04",
        name: "Cesar Virgílio Conventi",
        depoimento:
            "Trabalhar com o Fotop é  uma experiência que me agrega demais. Em  tempos de pandemia , onde tudo estava incerto para nós que trabalhamos com eventos, o Fotop me proporcionou uma renda que me fez respirar e me ajudou muito nesse momento conturbado que passamos. Minha caminhada na fotografia é de longa data e a valorização cada vez mais é necessária para continuarmos na jornada. O Fotop traz clareza sobre a importância da fotografia,  evidenciando  que temos nossos valores como fotógrafo profissional,  seja num simples evento ou em um trabalho mais complexo.Isso tudo  enaltece  a profissão de fotógrafo, que  sempre será garantida no Fotop e diante de todos os colaboradores que estão sempre juntos conosco. ",
        image: "/images/depo-04.jpg",
    },
    {
        id: "05",
        name: "Mauricio Latorre Fotógrafo ",
        depoimento:
            "O Fotop é uma empresa que abriu portas no mercado de fotografia, principalmente no período de pandemia (coronavírus) onde os eventos foram a zero, através da plataforma Fotop foi possível continuar o serviço de fotografia no mercado imobiliário (com todos os cuidados necessários de segurança). Além disso, o Fotop escuta seus colaboradores e está sempre buscando criar um ambiente que seja favorável.    ",
        image: "/images/depo-05.jpg",
    },
    {
        id: "06",
        name: "Luiz Bahú   ",
        depoimento:
            "A melhor parte da parceria com o Fotop é a sensação de respeito pelo meu trabalho. Tenho a segurança de ter o respaldo necessário no dia a dia, sempre sou bem atendido e auxiliado assim que tenho alguma questão a ser solucionada. Gosto bastante dessa parceria,  sinto que ela sempre será positiva para ambos e sempre trará bons frutos!  ",
        image: "/images/depo-06.jpg",
    },
    {
        id: "07",
        name: "Joana Magalhães",
        depoimento:
            "Sobre trabalhar com o Fotop. Só tenho que agradecer a Deus e a toda essa empresa maravilhosa que é uma mãe para mim. Mas gostaria de destacar um agradecimento em especial a algumas pessoas, que no momento mais difícil da minha vida, essas pessoas me abraçaram e me ajudaram muito. Carol, Gabi Borges, Adrielli e Bruno. Não há palavras que definam a gratidão que eu tenho dentro do meu coração por ter vocês na minha vida. Só gratidão (Não tem emoticon, mas imaginem aí um coraçãozinho pra vocês, rsrs) E pra concluir o que posso dizer é: Eu sou muito feliz por trabalhar em uma empresa tão maravilhosa e humanizada como o Fotop. E para quem ainda não me conhece.",
        image: "/images/depo-07.jpg",
    },
    {
        id: "08",
        name: "Leandro de Oliveira",
        depoimento:
            "Quando comecei no fotop, estava perdido sobre os caminhos profissionais que gostaria de seguir dentro da fotografia, porém com a oportunidade, fui inserido em um mundo com grandes possibilidades de aprendizado e todo o feedback positivo que recebi da equipe fotop é algo que vou levar para minha vida toda. Além de toda estrutura que consegui adquirir a partir dali. Agradeço a todos que acreditaram e acreditam em mim. Muito obrigado a todos!",
        image: "/images/depo-08.jpg",
    },
];
