import styled from "styled-components";

export const Background = styled.div`
    background: rgba(0, 0, 0, 1);
    position: relative;
`;

export const Content = styled.footer`
    .info-contatct {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 2rem;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .social-media {
        p {
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            padding: 0.5rem;
        }

        a {
            width: 1.3rem;
            height: 1.3rem;
            margin: 0.3rem;
            padding: 0.5rem;
            border-radius: 50%;

            background: #fe8418;
        }

        @media screen and (max-width: 768px) {
            margin-top: 3rem;

            p {
                text-align: center;
            }
        }
    }

    .useful-links {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 3rem 0;

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;

            strong {
                color: #fff;
                padding: 0.5rem 0;
            }

            a {
                padding: 0.4rem 0;
                color: #fff;
                font-weight: 400;

                &:hover {
                    color: #fe8418;
                    text-decoration: underline;
                }
            }
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;

            a {
                text-align: center;
            }
        }

        @media screen and (max-width: 468px) {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }

    .rodape {
        border-top: 0.5px solid #9d9d9d;
        padding: 0.5rem 1rem;
        p {
            text-align: center;
            color: #c4c4c4;

            a {
                color: #c4c4c4;
            }
        }

        @media screen and (max-width: 468px) {
            text-align: center;
        }
    }
`;

export const FormContainer = styled.div`
    position: relative;
    p {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
    }

    @media screen and (max-width: 768px) {
        p {
            margin-top: 2rem;
            text-align: center;
        }
    }
`;

export const Form = styled.form`
    margin-top: 1rem;
    input {
        background: transparent;
        border: none;
        color: #fff;
        padding: 1rem 0;
        border-bottom: 0.5px solid #c4c4c4;
        width: 25rem;
        outline: none;

        &:focus {
            border-bottom: 0.5px solid #fe8418;
        }
    }

    button {
        background: transparent;
        padding: 0.4rem 1.3rem;
        font-size: 0.8rem;
        border: 0.5px solid #c4c4c4;
        border-radius: 1.5rem;
        color: #fe8418;
        text-transform: uppercase;

        position: absolute;
        top: 2.5rem;
        right: 0.2rem;

        transition: all 0.2s ease-in-out;

        &:hover {
            background: #c4c4c4;
            color: #000;
        }
    }

    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        input {
            margin-top: 1rem;
            width: 18rem;

            font-size: 0.9rem;
        }

        button {
            top: 9.5rem;
            padding: 0.2rem 1rem;
        }
    }

    @media screen and (max-width: 468px) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
`;
