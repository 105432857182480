import React from "react";
import { CardContainer } from "./styles";

type CardProps = {
    name?: string;
    depoimento?: string;
    image?: string;
};

const CardDepoCarrusel = ({ depoimento, image, name }: CardProps) => {
    return (
        <CardContainer>
            <img src={image} alt={`foto do fotografo ${name}`} />
            <div>
                <p>{depoimento}</p>
                <br />
                <strong>{name}</strong>
            </div>
        </CardContainer>
    );
};

export default CardDepoCarrusel;
